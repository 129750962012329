import { Text } from "@daangn/carotene";
import {
  IconChevronDownFill,
  IconLocationpinFill,
} from "@daangn/react-monochrome-icon";
import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { GtmVariableName } from "~/utils/GtmVariable";

import * as css from "./SelectRegionButton.css";

interface SelectRegionButtonProps
  extends Exclude<HTMLAttributes<HTMLButtonElement>, "className" | "children"> {
  size: "small" | "large";
  currentRegionName: string;
}

const SelectRegionButton = forwardRef<
  HTMLButtonElement,
  SelectRegionButtonProps
>(({ size, currentRegionName, ...props }, ref) => (
  <button
    className={css.button({ size })}
    data-gtm={GtmVariableName.GnbLocation}
    {...props}
    ref={ref}
  >
    <IconLocationpinFill className={css.leftIcon} />
    <Text size="small" weight="strong" maxLines={1} className={css.buttonText}>
      {currentRegionName}
    </Text>
    <IconChevronDownFill className={css.expandIcon} size={14} />
  </button>
));

SelectRegionButton.displayName = "SelectRegionButton";

export default SelectRegionButton;
