import { Dialog, DialogTrigger, Text } from "@daangn/carotene";
import { atoms } from "@daangn/carotene/css";
import {
  IconCheckmarkFill,
  IconTriangleDownSmallFill,
} from "@daangn/react-monochrome-icon";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useLocation, useSubmit } from "@remix-run/react";
import clsx from "clsx";
import type { ReactNode } from "react";
import { forwardRef, useState } from "react";

import {
  AllPageSearchParamKey,
  CommonSearchParamKey,
  buildRegionParamValue,
} from "~/core";
import { Services } from "~/core/services";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useLocale } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";
import { getService } from "~/utils/getService";
import { trackSubmitSearchKeyword } from "~/utils/trackEvent";

import * as css from "./SearchForm.css";
import SearchInput from "./SearchInput";

type SearchFormProps = {
  region: { id: string; name: string };
  query: string | null;
  initService?: ServicesOption;
  searchAutoFocus?: boolean;
  size?: "medium" | "large";
};

const servicesOptionTextMap = {
  [Services.BUY_SELL]: "중고거래",
  [Services.REALTY]: "부동산",
  [Services.CARS]: "중고차",
  [Services.JOBS]: "알바",
  [Services.LOCAL_PROFILE]: "동네업체",
  [Services.GROUP]: "모임",
};

export type ServicesOption = keyof typeof servicesOptionTextMap;

function isServicesOption(value: unknown): value is ServicesOption {
  return (
    typeof value === "string" &&
    Object.prototype.hasOwnProperty.call(servicesOptionTextMap, value)
  );
}

const SERVICE_FALLBACK = Services.BUY_SELL;

const SELECT_SERVICE_ARIA_LABEL = "검색하려는 서비스를 선택하세요";

export function SearchForm(props: SearchFormProps) {
  const { t } = useLocale();
  const location = useLocation();
  const onSubmit = useSubmit();

  const initService = getService(location.pathname);
  const [service, setService] = useState<ServicesOption>(() =>
    isServicesOption(initService) ? initService : SERVICE_FALLBACK,
  );

  // TODO: 의미 명확하게 수정하기
  const keepSearchParams =
    initService === service &&
    [Services.JOBS, Services.REALTY].includes(initService);
  const searchParams = getNextSearchParams({
    search: location.search,
    excludeNames: [CommonSearchParamKey.In, "search"],
  });

  return (
    <form
      className={css.form({
        size: props.size,
      })}
      onSubmit={(event) => {
        onSubmit(event.currentTarget);

        const keyword = new FormData(event.currentTarget).get("search");

        if (typeof keyword === "string") {
          trackSubmitSearchKeyword({
            keyword,
            serviceName: service,
            countryCode: KarrotLocalCountryCode.KR,
          });

          const search = keepSearchParams
            ? searchParams
            : new URLSearchParams();
          search.set(
            CommonSearchParamKey.In,
            buildRegionParamValue({
              region: props.region,
            }),
          );
          search.set("search", keyword);

          window.location.href = `/${
            KarrotLocalCountryCode.KR
          }/${service}/?${search.toString()}`;
          event.preventDefault();
        }
      }}
    >
      {/* TODO: ServiceModal, ServiceDropdown 하나로 합치기 */}
      <ServiceModal
        service={service}
        onServiceChange={setService}
        className={clsx(css.mobileOnly, css.verticalDivider)}
      />
      <ServiceDropdown
        service={service}
        onServiceChange={setService}
        className={clsx(css.desktopOnly, css.verticalDivider)}
      />

      <div className={css.searchInputContainer}>
        <SearchInput
          data-gtm={GtmVariableName.GnbSearch}
          placeholder={t("GlobalNavigationBar.search_input_placeholder")}
          initialValue={props.query}
          name={AllPageSearchParamKey.Search}
          border={false}
          size={props.size}
          autoFocus={props.searchAutoFocus}
        />
      </div>
    </form>
  );
}

const ServiceDropdown = ({
  service,
  onServiceChange,
  className,
  portalContainer,
}: {
  service: ServicesOption;
  onServiceChange: (service: ServicesOption) => void;
  className?: string;
  portalContainer?: HTMLElement | null;
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <ServiceTriggerButton
          className={clsx(className, atoms({ flexShrink: 0 }))}
          aria-label={SELECT_SERVICE_ARIA_LABEL}
        >
          <Text weight="strong" size="medium" color="neutralMuted">
            {servicesOptionTextMap[service]}
          </Text>
        </ServiceTriggerButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal container={portalContainer}>
        <DropdownMenu.Content
          side="bottom"
          sideOffset={15}
          align="start"
          alignOffset={-12}
          className={css.viewport}
          forceMount
        >
          <DropdownMenu.RadioGroup
            value={service}
            onValueChange={(value) => {
              if (isServicesOption(value)) {
                onServiceChange(value);
              }
            }}
          >
            {Object.entries(servicesOptionTextMap).map(([value, title]) => (
              <DropdownMenu.RadioItem
                key={title}
                value={value}
                className={css.item}
              >
                {title}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const ServiceModal = ({
  service,
  onServiceChange,
  className,
  portalContainer,
}: {
  service: ServicesOption;
  onServiceChange: (service: ServicesOption) => void;
  className?: string;
  portalContainer?: HTMLElement | null;
}) => {
  const { isOpen, setOpenState, onClose } = useDisclosure();
  return (
    <Dialog open={isOpen} onOpenChange={setOpenState}>
      <DialogTrigger asChild>
        <ServiceTriggerButton
          className={clsx(className, atoms({ flexShrink: 0 }))}
          aria-label={SELECT_SERVICE_ARIA_LABEL}
        >
          <Text weight="strong" size="medium" color="neutralMuted">
            {servicesOptionTextMap[service]}
          </Text>
        </ServiceTriggerButton>
      </DialogTrigger>

      <DialogPrimitive.Portal container={portalContainer}>
        <DialogPrimitive.Overlay className={css.dialogOverlay} />
        <DialogPrimitive.Content className={css.dialogContent}>
          <ToggleGroup.Root
            type="single"
            defaultValue={service}
            onValueChange={(value) => {
              if (!isServicesOption(value)) {
                return;
              }

              onServiceChange(value);
              onClose();
            }}
            className={atoms({ paddingY: 2.5 })}
          >
            {Object.entries(servicesOptionTextMap).map(([value, title]) => (
              <ServiceTypeToggleItem key={title} label={title} value={value} />
            ))}
          </ToggleGroup.Root>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </Dialog>
  );
};

export const ServiceTypeToggleItem = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <ToggleGroup.Item value={value} className={css.toggleItem}>
      <span className={css.toggleItemText}>{label}</span>
      <IconCheckmarkFill size={18} className={css.toggleItemIcon} />
    </ToggleGroup.Item>
  );
};

type ServiceTriggerButtonProps = {
  children: ReactNode;
  "aria-label": string;
  className?: string;
};
const ServiceTriggerButton = forwardRef<
  HTMLButtonElement,
  ServiceTriggerButtonProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <button
      {...props}
      ref={forwardedRef}
      className={clsx(className, atoms({ cursor: "pointer" }))}
    >
      <div className={css.trigger}>
        {children}
        <IconTriangleDownSmallFill size={14} aria-hidden />
      </div>
    </button>
  );
});

function getNextSearchParams({
  search,
  excludeNames,
}: {
  search: string;
  excludeNames?: string[];
}) {
  const result = new URLSearchParams(search);
  for (const name of excludeNames ?? []) {
    result.delete(name);
  }
  return result;
}
