import type {
  PersistentStorage,
  Region,
} from "@karrotmarket-com/persistent-storage";
import type { HTMLAttributes, ReactElement } from "react";
import { forwardRef } from "react";

import LocationSelectModal from "~/figma-components/LocationSelectModal";
import { Link, useLocale } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";

import HorizontalScroll from "../../HorizontalScroll";
import * as css from "./SearchBar.css";
import SelectRegionButton from "./SelectRegionButton";

export interface SearchBarProps extends HTMLAttributes<HTMLDivElement> {
  region: { id: string; name: string };
  keywordType: "popular" | "related";
  keywordComponentType?: "chip" | "text";
  keywords: { value: string; href: string }[];
  searchForm: ReactElement;
  size?: "medium" | "large";

  onSaveRegionToStorage: PersistentStorage<Region>["set"];
}

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>(
  (
    {
      region,
      keywordType,
      keywords,
      className,
      searchForm,
      keywordComponentType,
      size = "medium",
      onSaveRegionToStorage,
      ...props
    },
    ref,
  ) => {
    const { t } = useLocale();

    const showPopularKeywords = keywords.length > 0;
    const isPopularKeywords = keywordType === "popular";
    const keywordsTitle = isPopularKeywords
      ? t("GlobalNavigationBar.search_keywords_title_popular")
      : t("GlobalNavigationBar.search_keywords_title_related");
    const keywordsGTM = isPopularKeywords
      ? GtmVariableName.GnbPopularKeyword
      : GtmVariableName.GnbRelatedKeyword;

    return (
      <div className={className} ref={ref} {...props}>
        <div
          className={css.container({
            showPopularKeywords,
          })}
        >
          <div className={css.searchAreaLeft}>
            <LocationSelectModal
              trigger={
                <SelectRegionButton
                  size={size}
                  currentRegionName={region.name}
                />
              }
              onSaveRegionToStorage={onSaveRegionToStorage}
            />
          </div>
          <div className={css.searchAreaRight}>
            {searchForm}
            {showPopularKeywords ? (
              <div className={css.keywordScrollableContainer}>
                <HorizontalScroll>
                  <div
                    className={css.keywordScrollable({
                      type: keywordComponentType,
                    })}
                  >
                    <div
                      className={css.keywordTitle({
                        type: keywordComponentType,
                      })}
                    >
                      {keywordsTitle}
                    </div>
                    <ul className={css.keywordList}>
                      {keywords.map(({ value, href }) => (
                        <li key={value} className={css.keywordContainer}>
                          <Link
                            data-gtm={keywordsGTM}
                            className={css.keyword({
                              type: keywordComponentType,
                            })}
                            href={href}
                          >
                            {value}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </HorizontalScroll>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

SearchBar.displayName = "SearchBar";
