import { Button, Flex } from "@daangn/carotene";
import {
  IconHorizline3VerticalLine,
  IconXmarkLine,
} from "@daangn/react-monochrome-icon";
import {
  BrowserConfirmCountryBannerCookieStorage,
  BrowserRegionCookieStorage,
} from "@karrotmarket-com/persistent-storage/client";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { KR_MAIN_FEED_ONELINK } from "~/constants";
import { CommonSearchParamKey, buildRegionParamValue } from "~/core";
import LocationSelectModal from "~/figma-components/LocationSelectModal";
import useDetectUserAgent from "~/hooks/useDetectUserAgent";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useHydrated } from "~/hooks/useHydrated";
import { useModalState } from "~/hooks/useModalState";
import { Position } from "~/hooks/useMoveToApp";
import { useTrackClickButtonMoveToApp } from "~/hooks/useTrackClickButtonMoveToApp";
import { Link, useLocale, useRootLoaderData } from "~/remix-lib";
import type { RootLoaderData } from "~/root";
import CountrySelectBanner from "~/services/CountrySelectBanner";
import { KRQrModal } from "~/services/QrModal";
import { krServiceHomeUrl, serviceNameMap } from "~/site-urls";
import { GtmVariableName } from "~/utils/GtmVariable";
import { calcTopSpace } from "~/utils/calcTopSpace";
import { makeKeywordLinks } from "~/utils/makeKeywordLinks";

import KarrotLogo from "../../KarrotLogo";
import PageMaxWidthWrapper from "../../PageMaxWidthWrapper";
import { parseNavLinkWithSubLinksList } from "../GNBParse";
import { SearchButton } from "../SearchButton";
import {
  SearchPopover,
  SearchPopoverAnchor,
  SearchPopoverContent,
  SearchPopoverTrigger,
} from "../SearchPopover";
import { useDetailPageGNB } from "../useDetailPageGNB";
import { useSearchButton } from "../useSearchButton";
import { FixedTopSearchBar } from "./FixedTopSearchBar";
import * as css from "./GlobalNavigationBar.css";
import HamburgerMenu from "./GlobalNavigationBar__HamburgerMenu";
import { NavigationMenu } from "./NavigationMenu";
import { SearchBar } from "./SearchBar";
import { SearchForm } from "./SearchForm";
import SelectRegionButton from "./SelectRegionButton";

const regionBrowserStorage = BrowserRegionCookieStorage.of();
const countryBannerBrowserStorage =
  BrowserConfirmCountryBannerCookieStorage.of();

export type GlobalNavigationBarProps = {
  data: RootLoaderData["__gnb"]["data"];
  query: string | null;
  showFixedTopSearchBar: boolean;
  showNavigationMenu?: boolean;
  isDetailPage?: boolean;
  isIndexPage?: boolean;
};

const GlobalNavigationBar: React.FC<GlobalNavigationBarProps> = ({
  isDetailPage = false,
  isIndexPage = false,
  showNavigationMenu = true,
  ...props
}) => {
  const userAgent = useDetectUserAgent();
  const rootData = useRootLoaderData();
  const { trackClickButtonMoveToApp } = useTrackClickButtonMoveToApp();
  const { t } = useLocale();

  const hydrated = useHydrated();
  const [showBorder, setShowBorder] = useState(false);
  const showSearchButton = useSearchButton(false);

  const [state, toggle] = useModalState({
    transitionDuration: css.HAMBURGER_TRANSITION_DURATION,
  });

  const gnbState = useDetailPageGNB({
    isDetailPage: isDetailPage,
    isInnerScrollActive: () => state !== "exit-done",
  });
  const [bannerClose, setBannerClose] = useState<boolean>();

  const qrModalDisclosure = useDisclosure();
  const isEntered = state === "enter-active" || state === "enter-done";
  const isSameCountryAccess =
    rootData.countryCodeByIp === rootData.karrotLocalCountryCode ||
    // Note daangn.com(KR)일 경우 사용자가 위치한 국가와 상관없이 배너를 노출하지 않습니다.
    rootData.origin.includes("daangn.com");

  const keywordLinks = makeKeywordLinks({
    pathname: krServiceHomeUrl[serviceNameMap[props.data.serviceName]],
    region: rootData.region,
    keywordRecord: props.data.keywords,
  });

  const searchBarSize = isIndexPage ? "large" : "medium";

  const clickDownloadButton = () => {
    trackClickButtonMoveToApp({
      position: Position.GNB,
    });
    if (userAgent.isMobile) {
      window.open(KR_MAIN_FEED_ONELINK);
      return;
    }

    qrModalDisclosure.onOpen();
  };

  useEffect(() => {
    setShowBorder(hydrated && window.scrollY > 0);
  }, [hydrated]);

  useEffect(() => {
    const onScroll = () => {
      setShowBorder(window.scrollY > 0);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [setShowBorder]);

  const parsedNavLinkWithSubLinksList = parseNavLinkWithSubLinksList(
    props.data.GNBSubLink?.NavLinkWithSubLinks,
  );

  const topSpace = calcTopSpace({
    checked: rootData.countryConfirmBannerCheck,
    isSameCountryAccess,
    close: bannerClose,
    hamburgerEntered: isEntered,
    countryCodeByIp: rootData.countryCodeByIp,
  });

  return (
    <>
      <div
        style={assignInlineVars({
          [css.topSpaceBase]: topSpace.base,
          [css.topSpaceLarge]: topSpace.large,
        })}
        className={css.topSpace}
      />
      <div className={css.fixed} data-border={showBorder} data-state={gnbState}>
        <CountrySelectBanner
          onBannerClose={() => {
            countryBannerBrowserStorage.update({
              disabled: true,
            });
            setBannerClose(true);
          }}
          hamburgerEntered={isEntered}
        />
        <PageMaxWidthWrapper>
          <div className={css.top}>
            <Link
              data-gtm={GtmVariableName.GnbLogo}
              href={`/kr/?${CommonSearchParamKey.In}=${buildRegionParamValue({ region: rootData.region })}`}
              className={css.logo}
              aria-label="당근"
            >
              <KarrotLogo location="gnb" />
            </Link>
            {showNavigationMenu ? (
              <NavigationMenu
                linkWithSubLinks={parsedNavLinkWithSubLinksList}
                className={css.desktopOnly}
              />
            ) : null}
            <Flex
              alignItems="center"
              gap={4}
              display={{ base: "none", medium: "flex" }}
            >
              <SearchPopover>
                <SearchPopoverTrigger asChild>
                  <SearchButton
                    visible={isDetailPage || showSearchButton}
                    label="검색"
                    className={css.searchPopoverTriggerSelector}
                  />
                </SearchPopoverTrigger>
                <SearchPopoverAnchor className={css.searchPopoverAnchor} />
                <SearchPopoverContent
                  className={css.searchPopoverContent}
                  hideWhenDetached
                >
                  <SearchBar
                    region={rootData.region}
                    keywordType={props.data.keywords.type}
                    keywords={keywordLinks}
                    size={searchBarSize}
                    searchForm={
                      <SearchForm
                        region={rootData.region}
                        query={props.query}
                        searchAutoFocus={true}
                        size={searchBarSize}
                      />
                    }
                    onSaveRegionToStorage={regionBrowserStorage.update}
                  />
                </SearchPopoverContent>
              </SearchPopover>
              <Button
                size="medium"
                variant="brand"
                data-gtm={GtmVariableName.GnbDownload}
                onClick={clickDownloadButton}
              >
                {t("GlobalNavigationBar.app_download_button_label")}
              </Button>
            </Flex>
            <div className={clsx(css.topRightActionButton, css.mobileOnly)}>
              <Flex
                gap={3}
                alignItems="center"
                className={css.relatedHamburgerTransition({
                  transitionState: state,
                })}
              >
                <motion.div
                  initial={false}
                  animate={{
                    x: isDetailPage || showSearchButton ? 0 : 44,
                  }}
                  transition={{
                    duration: 0.4,
                    ease: "easeInOut",
                  }}
                >
                  <LocationSelectModal
                    trigger={
                      <SelectRegionButton
                        size="small"
                        currentRegionName={rootData.region.name}
                      />
                    }
                    onSaveRegionToStorage={regionBrowserStorage.update}
                  />
                </motion.div>
                <SearchPopover>
                  <SearchPopoverTrigger asChild>
                    <SearchButton
                      visible={isDetailPage || showSearchButton}
                      label="검색"
                      className={css.searchPopoverTriggerSelector}
                    />
                  </SearchPopoverTrigger>
                  <SearchPopoverAnchor className={css.searchPopoverAnchor} />
                  <SearchPopoverContent
                    className={css.searchPopoverContent}
                    hideWhenDetached
                  >
                    <SearchBar
                      region={rootData.region}
                      keywordType={props.data.keywords.type}
                      keywords={keywordLinks}
                      size={searchBarSize}
                      searchForm={
                        <SearchForm
                          region={rootData.region}
                          query={props.query}
                          searchAutoFocus={true}
                          size={searchBarSize}
                        />
                      }
                      onSaveRegionToStorage={regionBrowserStorage.update}
                    />
                  </SearchPopoverContent>
                </SearchPopover>
              </Flex>
              <div className={css.topRightHamburgerContainer}>
                <button
                  className={css.hamburgerButton}
                  onClick={toggle}
                  type="button"
                >
                  <div
                    data-gtm={GtmVariableName.GnbHamburger}
                    className={css.hamburgerButtonIcon}
                  >
                    {isEntered ? (
                      <IconXmarkLine size={24} />
                    ) : (
                      <IconHorizline3VerticalLine size={24} />
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <HamburgerMenu
            navLinks={parsedNavLinkWithSubLinksList}
            appDownloadButtonLabel={t(
              "GlobalNavigationBar.app_download_button_label",
            )}
            appDownloadCaptionLabel={t(
              "GlobalNavigationBar.app_download_caption_label",
            )}
            moreSubLinkButtonLabel={t(
              "GlobalNavigationBar.see_more_button_label",
            )}
            onClickAppDownloadButton={clickDownloadButton}
            state={state}
            onDimClick={toggle}
          />
        </PageMaxWidthWrapper>
      </div>
      {props.showFixedTopSearchBar && (
        <FixedTopSearchBar
          region={rootData.region}
          keywordType={props.data.keywords.type}
          keywords={keywordLinks}
          size={searchBarSize}
          searchForm={
            <SearchForm
              region={rootData.region}
              query={props.query}
              size={searchBarSize}
            />
          }
          onSaveRegionToStorage={regionBrowserStorage.update}
        />
      )}
      <KRQrModal
        open={qrModalDisclosure.isOpen}
        onOpenChange={qrModalDisclosure.setOpenState}
        qrLink={KR_MAIN_FEED_ONELINK}
      />
    </>
  );
};

export default GlobalNavigationBar;
