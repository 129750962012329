import React from "react";

import { normalizeHref } from "../core";
import { useRootLoaderData } from "./useRootLoaderData";

type LinkProps = {
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...attrs }, ref) => {
    const rootData = useRootLoaderData();

    return (
      <a
        {...attrs}
        ref={ref}
        href={
          attrs.href
            ? normalizeHref(attrs.href, {
                origin: rootData.origin,
                region: rootData.region,
              })
            : undefined
        }
      >
        {children}
      </a>
    );
  },
);
Link.displayName = "Link";

export default Link;
