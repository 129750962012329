import { Button, Icon, Text } from "@daangn/carotene";
import {
  IconArrowUpRightLine,
  IconChevronDownFill,
} from "@daangn/react-monochrome-icon";
import * as Collapsible from "@radix-ui/react-collapsible";
import clsx from "clsx";
import { isEmpty } from "lodash";
import type { CSSProperties } from "react";

import { useHydrated } from "~/hooks/useHydrated";
import type { ModalState } from "~/hooks/useModalState";
import { Link } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";

import PageMaxWidthWrapper from "../../PageMaxWidthWrapper";
import type { NavLinkWithSubLinksList } from "../GNBParse";
import { HAMBURGER_TRANSITION_DURATION } from "./GlobalNavigationBar.css";
import * as css from "./GlobalNavigationBar__HamburgerMenu.css";

function linkFadeAnimationDelay(i: number) {
  return HAMBURGER_TRANSITION_DURATION / 1.5 + 60 * i;
}

function subLinkFadeAnimationDelay(index: number, length: number) {
  return (css.SUBLINKS_CONTENT_TRANSITION_DURATION / length) * index;
}

type HamburgerMenuSubLinksProps = {
  linkWithSubLinks: NavLinkWithSubLinksList[0];
  animationIndex: number;
  style?: CSSProperties;
  className?: string;
};
const HamburgerMenuSubLinks = (props: HamburgerMenuSubLinksProps) => {
  return (
    <Collapsible.Root>
      <Collapsible.Trigger
        style={props.style}
        className={clsx(props.className, css.subLinksTrigger)}
      >
        {props.linkWithSubLinks.Link.NavLinkLabel}
        <Icon
          svg={
            <IconChevronDownFill
              aria-hidden
              className={css.subLinksTriggerIcon}
            />
          }
          size={4}
          color="neutralSubtle"
        />
      </Collapsible.Trigger>
      <Collapsible.Content className={css.subLinksContent}>
        <ul>
          {props.linkWithSubLinks.SubLinks?.map((link, j) => (
            <li key={link.NavLinkLabel}>
              {link.NavLinkExternal ? (
                <a
                  className={css.navSubLink}
                  href={link.NavLinkLink}
                  style={{
                    animationDelay: `${subLinkFadeAnimationDelay(
                      j,
                      props.linkWithSubLinks.SubLinks?.length ?? 0,
                    )}ms`,
                  }}
                  // biome-ignore lint/a11y/noBlankTarget: 당근 타팀 서비스 링크에는 noreferrer noopener 를 적용하지 않아요
                  target="_blank"
                >
                  {link.NavLinkLabel}
                  <Icon
                    svg={<IconArrowUpRightLine aria-hidden size={16} />}
                    color="neutralSubtle"
                  />
                </a>
              ) : (
                <Link
                  className={css.navSubLink}
                  href={link.NavLinkLink}
                  style={{
                    animationDelay: `${subLinkFadeAnimationDelay(
                      j,
                      props.linkWithSubLinks.SubLinks?.length ?? 0,
                    )}ms`,
                  }}
                >
                  {link.NavLinkLabel}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export type HamburgerMenuProps = {
  navLinks: NavLinkWithSubLinksList;
  appDownloadButtonLabel: string;
  appDownloadCaptionLabel: string;
  moreSubLinkButtonLabel: string;
  onClickAppDownloadButton: () => void;
  state: ModalState;
  onDimClick?: () => void;
};

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const hydrated = useHydrated();

  if (!hydrated) {
    return null;
  }

  if (props.state === "exit-done") {
    return null;
  }

  return (
    <>
      <div
        className={css.dim({
          transitionState: props.state,
        })}
        onClick={props.onDimClick}
      />
      <div
        className={css.overlay({
          transitionState: props.state,
        })}
      >
        <PageMaxWidthWrapper className={css.container}>
          <nav>
            <ul>
              {props.navLinks.map((linkWithSubLinks, i) => (
                <li
                  key={linkWithSubLinks.Link.NavLinkLabel}
                  className={css.navItem}
                >
                  {!isEmpty(linkWithSubLinks.SubLinks) ? (
                    <HamburgerMenuSubLinks
                      linkWithSubLinks={linkWithSubLinks}
                      style={{
                        animationDelay: `${linkFadeAnimationDelay(i)}ms`,
                      }}
                      className={css.navLink}
                      animationIndex={i}
                    />
                  ) : linkWithSubLinks.Link.NavLinkExternal ? (
                    <a
                      data-gtm={GtmVariableName.GnbMenu}
                      className={css.navLink}
                      href={linkWithSubLinks.Link.NavLinkLink}
                      style={{
                        animationDelay: `${linkFadeAnimationDelay(i)}ms`,
                      }}
                      // biome-ignore lint/a11y/noBlankTarget: 당근 타팀 서비스 링크에는 noreferrer noopener 를 적용하지 않아요
                      target="_blank"
                    >
                      {linkWithSubLinks.Link.NavLinkLabel}
                    </a>
                  ) : (
                    <Link
                      data-gtm={GtmVariableName.GnbMenu}
                      className={css.navLink}
                      href={linkWithSubLinks.Link.NavLinkLink}
                      style={{
                        animationDelay: `${linkFadeAnimationDelay(i)}ms`,
                      }}
                    >
                      {linkWithSubLinks.Link.NavLinkLabel}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div
            className={css.bottom}
            style={{
              animationDelay: `${linkFadeAnimationDelay(
                props.navLinks.length,
              )}ms`,
            }}
          >
            <Text size="medium" color="neutral">
              {props.appDownloadCaptionLabel}
            </Text>
            <div className={css.bottomButton}>
              <Button
                size="large"
                variant="brand"
                data-gtm={GtmVariableName.GnbDownload}
                onClick={props.onClickAppDownloadButton}
              >
                {props.appDownloadButtonLabel}
              </Button>
            </div>
          </div>
        </PageMaxWidthWrapper>
      </div>
    </>
  );
};

export default HamburgerMenu;
