import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isNil } from "lodash";

import {
  CONFIRM_COUNTRY_BANNER,
  GNB_HEIGHT,
} from "~/figma-components/GlobalNavigationBar.css";

type CalcTopSpaceParams = {
  countryCodeByIp: KarrotLocalCountryCode | null;
  /**
   * 배너 클릭 여부 쿠키 값 (boolean)
   */
  checked: boolean;
  /**
   * 접속한 ip의 국가 코드와 서비스되는 국가 코드 일치 여부
   */
  isSameCountryAccess: boolean;
  /**
   * 리액트 상태로 관리되는 close 버튼 클릭 여부
   */
  close?: boolean;
  /**
   * 햄버거 메뉴 오픈 여부
   */
  hamburgerEntered?: boolean;
};
export function calcTopSpace({
  countryCodeByIp,
  checked,
  isSameCountryAccess,
  close,
  hamburgerEntered,
}: CalcTopSpaceParams) {
  if (checked || close || isNil(countryCodeByIp)) {
    return {
      base: `${GNB_HEIGHT.base}px`,
      large: `${GNB_HEIGHT.large}px`,
    };
  }

  if (isSameCountryAccess) {
    return {
      base: `${GNB_HEIGHT.base}px`,
      large: `${GNB_HEIGHT.large}px`,
    };
  }

  if (hamburgerEntered) {
    return {
      base: `${GNB_HEIGHT.base}px`,
      large: `${GNB_HEIGHT.large}px`,
    };
  }

  return {
    base: `${GNB_HEIGHT.base + CONFIRM_COUNTRY_BANNER.base}px`,
    large: `${GNB_HEIGHT.large + CONFIRM_COUNTRY_BANNER.large}px`,
  };
}
