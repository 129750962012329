import { Button, Icon, Text } from "@daangn/carotene";
import { IconChevronDownFill } from "@daangn/react-monochrome-icon";

import { useDisclosure } from "~/hooks/useDisclosure";
import { useHydrated } from "~/hooks/useHydrated";
import { ExternalLink } from "~/services/ExternalLink";
import type { NavLinkWithSubLinksList } from "~/services/gnb/GNBParse";

import type { ModalState } from "../hooks/useModalState";
import { Link } from "../remix-lib";
import PageMaxWidthWrapper from "../services/PageMaxWidthWrapper";
import { GtmVariableName } from "../utils/GtmVariable";
import { HAMBURGER_TRANSITION_DURATION } from "./GlobalNavigationBar.css";
import * as css from "./GlobalNavigationBar__HamburgerMenu.css";

function fadeAnimationDelay(i: number) {
  return HAMBURGER_TRANSITION_DURATION / 1.5 + 60 * i;
}

type HamburgerMenuSubLinksProps = {
  subLinks: NavLinkWithSubLinksList[0]["SubLinks"];
  moreSubLinkButtonLabel: string;
  animationIndex: number;
};
const HamburgerMenuSubLinks = (props: HamburgerMenuSubLinksProps) => {
  const { isOpen: expanded, onOpen: expand } = useDisclosure();

  return (
    <ul
      className={css.navSubLinkList({
        expanded,
      })}
    >
      {props.subLinks?.map((subLink, j) => (
        <li className={css.navSubLinkContainer} key={j}>
          {subLink.NavLinkExternal ? (
            <ExternalLink
              data-gtm={GtmVariableName.GnbCategory}
              className={css.navSubLink}
              href={subLink.NavLinkLink}
              style={{
                animationDelay: `${
                  fadeAnimationDelay(props.animationIndex) + 15
                }ms`,
              }}
            >
              {subLink.NavLinkLabel}
            </ExternalLink>
          ) : (
            <Link
              data-gtm={GtmVariableName.GnbCategory}
              className={css.navSubLink}
              href={subLink.NavLinkLink}
              style={{
                animationDelay: `${
                  fadeAnimationDelay(props.animationIndex) + 15
                }ms`,
              }}
            >
              {subLink.NavLinkLabel}
            </Link>
          )}
        </li>
      ))}
      {!expanded && (
        <li>
          <button
            className={css.navSubLinkMore}
            style={{
              animationDelay: `${
                fadeAnimationDelay(props.animationIndex) + 15
              }ms`,
            }}
            onClick={expand}
          >
            <div className={css.navSubLinkMoreIconContainer}>
              <Icon
                svg={<IconChevronDownFill aria-hidden />}
                size={3}
                color="neutralMuted"
              />
            </div>
            {props.moreSubLinkButtonLabel}
          </button>
        </li>
      )}
    </ul>
  );
};

export type HamburgerMenuProps = {
  navLinks: NavLinkWithSubLinksList;
  appDownloadButtonLabel: string;
  appDownloadCaptionLabel: string;
  moreSubLinkButtonLabel: string;
  onClickAppDownloadButton: () => void;
  state: ModalState;
  onDimClick?: () => void;
};

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const hydrated = useHydrated();

  if (!hydrated) {
    return null;
  }

  if (props.state === "exit-done") {
    return null;
  }

  return (
    <>
      <div
        className={css.dim({
          transitionState: props.state,
        })}
        onClick={props.onDimClick}
      />
      <div
        className={css.overlay({
          transitionState: props.state,
        })}
      >
        <PageMaxWidthWrapper>
          <nav>
            <ul className={css.navList}>
              {props.navLinks.map((linkWithSubLinks, i) => (
                <li key={i} className={css.navLinkItem}>
                  <Link
                    data-gtm={GtmVariableName.GnbMenu}
                    className={css.navLink}
                    href={linkWithSubLinks.Link.NavLinkLink}
                    style={{
                      animationDelay: `${fadeAnimationDelay(i)}ms`,
                    }}
                  >
                    {linkWithSubLinks.Link.NavLinkLabel}
                  </Link>
                  {linkWithSubLinks.SubLinks && (
                    <HamburgerMenuSubLinks
                      subLinks={linkWithSubLinks.SubLinks}
                      moreSubLinkButtonLabel={props.moreSubLinkButtonLabel}
                      animationIndex={i}
                    />
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className={css.space} />
          <div
            className={css.bottom}
            style={{
              animationDelay: `${fadeAnimationDelay(props.navLinks.length)}ms`,
            }}
          >
            <Text size="medium" color="neutral">
              {props.appDownloadCaptionLabel}
            </Text>
            <div className={css.bottomButton}>
              <Button
                size="large"
                variant="brandWeak"
                data-gtm={GtmVariableName.GnbDownload}
                onClick={props.onClickAppDownloadButton}
              >
                {props.appDownloadButtonLabel}
              </Button>
            </div>
          </div>
        </PageMaxWidthWrapper>
      </div>
    </>
  );
};

export default HamburgerMenu;
