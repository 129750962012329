import { Button, Flex } from "@daangn/carotene";
import {
  IconHorizline3VerticalLine,
  IconXmarkLine,
} from "@daangn/react-monochrome-icon";
import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import {
  BrowserConfirmCountryBannerCookieStorage,
  BrowserRegionCookieStorage,
} from "@karrotmarket-com/persistent-storage/client";
import { useSubmit } from "@remix-run/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import {
  AllPageSearchParamKey,
  CommonSearchParamKey,
  buildRegionParamValue,
} from "~/core";
import { Services } from "~/core/services";
import { useHydrated } from "~/hooks/useHydrated";
import { Position, useMoveToApp } from "~/hooks/useMoveToApp";
import type { RootLoaderData } from "~/root";
import CountrySelectBanner from "~/services/CountrySelectBanner";
import { ExternalLink } from "~/services/ExternalLink";
import GlobalQrModal from "~/services/QrModal";
import { BuySellSearchParams } from "~/services/buy-sell/buySellSearchParams";
import { FixedTopSearchBar } from "~/services/gnb/FixedTopSearchBar";
import { parseNavLinkWithSubLinksList } from "~/services/gnb/GNBParse";
import { SearchBar } from "~/services/gnb/SearchBar";
import { SearchButton } from "~/services/gnb/SearchButton";
import {
  SearchPopover,
  SearchPopoverAnchor,
  SearchPopoverContent,
  SearchPopoverTrigger,
} from "~/services/gnb/SearchPopover";
import SelectRegionButton from "~/services/gnb/SelectRegionButton";
import { useDetailPageGNB } from "~/services/gnb/useDetailPageGNB";
import { useSearchButton } from "~/services/gnb/useSearchButton";
import { globalServiceHomeUrl } from "~/site-urls";
import { calcTopSpace } from "~/utils/calcTopSpace";
import { makeKeywordLinks } from "~/utils/makeKeywordLinks";
import { trackSubmitSearchKeyword } from "~/utils/trackEvent";

import { GL_APPSFLYER_LINK } from "../constants";
import { useModalState } from "../hooks/useModalState";
import { Link, useLocale, useRootLoaderData } from "../remix-lib";
import KarrotLogo from "../services/KarrotLogo";
import PageMaxWidthWrapper from "../services/PageMaxWidthWrapper";
import { GtmVariableName } from "../utils/GtmVariable";
import * as css from "./GlobalNavigationBar.css";
import HamburgerMenu from "./GlobalNavigationBar__HamburgerMenu";
import LocationSelectModal from "./LocationSelectModal";
import SearchInput from "./SearchInput";

const regionBrowserStorage = BrowserRegionCookieStorage.of();
const countryBannerBrowserStorage =
  BrowserConfirmCountryBannerCookieStorage.of();

export type GlobalNavigationBarProps = {
  data: RootLoaderData["__gnb"]["data"];
  query: string | null;
  showFixedTopSearchBar: boolean;
  isDetailPage?: boolean;
};

const GlobalNavigationBar: React.FC<GlobalNavigationBarProps> = ({
  isDetailPage = false,
  ...props
}) => {
  const rootData = useRootLoaderData();

  const { t } = useLocale();

  const hydrated = useHydrated();
  const [showBorder, setShowBorder] = useState(false);
  const showSearchButton = useSearchButton(false);

  const [state, toggle] = useModalState({
    transitionDuration: css.HAMBURGER_TRANSITION_DURATION,
  });
  const gnbState = useDetailPageGNB({
    isDetailPage,
    isInnerScrollActive: () => state !== "exit-done",
  });
  const [bannerClose, setBannerClose] = useState<boolean>();

  const { moveToApp, setQrModalStatus, isQrModalOpen } = useMoveToApp();

  const isEntered = state === "enter-active" || state === "enter-done";
  const isSameCountryAccess =
    rootData.countryCodeByIp === rootData.karrotLocalCountryCode ||
    // Note daangn.com(KR)일 경우 사용자가 위치한 국가와 상관없이 배너를 노출하지 않습니다.
    rootData.origin.includes("daangn.com");

  const keywordLinks = makeKeywordLinks({
    pathname: `/${rootData.karrotLocalCountryCode}${globalServiceHomeUrl.BUY_SELL}`,
    region: rootData.region,
    keywordRecord: props.data.keywords,
  });

  const clickDownloadButton = () => {
    moveToApp({
      position: Position.GNB,
      appScheme: GL_APPSFLYER_LINK.Mobile.Feed,
    });
  };

  useEffect(() => {
    setShowBorder(hydrated && window.scrollY > 0);
  }, [hydrated]);

  useEffect(() => {
    const onScroll = () => {
      setShowBorder(window.scrollY > 0);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [setShowBorder]);

  const parsedNavLinkWithSubLinksList = parseNavLinkWithSubLinksList(
    props.data.GNBSubLink?.NavLinkWithSubLinks,
  );

  const topSpace = calcTopSpace({
    checked: rootData.countryConfirmBannerCheck,
    isSameCountryAccess,
    close: bannerClose,
    hamburgerEntered: isEntered,
    countryCodeByIp: rootData.countryCodeByIp,
  });

  return (
    <>
      <div
        style={assignInlineVars({
          [css.topSpaceBase]: topSpace.base,
          [css.topSpaceLarge]: topSpace.large,
        })}
        className={css.topSpace}
      />
      <div className={css.fixed} data-border={showBorder} data-state={gnbState}>
        <CountrySelectBanner
          onBannerClose={() => {
            countryBannerBrowserStorage.update({
              disabled: true,
            });
            setBannerClose(true);
          }}
          hamburgerEntered={isEntered}
        />
        <PageMaxWidthWrapper>
          <div className={css.top}>
            <Link
              data-gtm={GtmVariableName.GnbLogo}
              href={`/${rootData.karrotLocalCountryCode}/?${
                CommonSearchParamKey.In +
                "=" +
                buildRegionParamValue({ region: rootData.region })
              }`}
              className={css.logo}
              aria-label="Go to karrotmarket.com home"
            >
              <KarrotLogo location="gnb" />
            </Link>
            <nav className={css.topRightNav} aria-label="Navigation menus">
              <ul className={css.topRightNavList}>
                {parsedNavLinkWithSubLinksList.map((linkWithSubLinks, i) => (
                  <li key={i}>
                    {linkWithSubLinks.Link.NavLinkExternal ? (
                      <ExternalLink
                        data-gtm={GtmVariableName.GnbMenu}
                        className={css.navLink}
                        href={linkWithSubLinks.Link.NavLinkLink}
                      >
                        {linkWithSubLinks.Link.NavLinkLabel}
                      </ExternalLink>
                    ) : (
                      <Link
                        data-gtm={GtmVariableName.GnbMenu}
                        className={css.navLink}
                        href={linkWithSubLinks.Link.NavLinkLink}
                      >
                        {linkWithSubLinks.Link.NavLinkLabel}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <Flex
              alignItems="center"
              gap={4}
              display={{ base: "none", medium: "flex" }}
            >
              <SearchPopover>
                <SearchPopoverTrigger asChild>
                  <SearchButton
                    visible={isDetailPage || showSearchButton}
                    label="Search"
                    className={css.searchPopoverTriggerSelector}
                  />
                </SearchPopoverTrigger>
                <SearchPopoverAnchor className={css.searchPopoverAnchor} />
                <SearchPopoverContent
                  className={css.searchPopoverContent}
                  hideWhenDetached
                >
                  <SearchBar
                    region={rootData.region}
                    keywordType={props.data.keywords.type}
                    keywords={keywordLinks}
                    searchForm={
                      <SearchForm
                        karrotLocalCountryCode={rootData.karrotLocalCountryCode}
                        region={rootData.region}
                        query={props.query}
                        searchAutoFocus={true}
                      />
                    }
                    onSaveRegionToStorage={regionBrowserStorage.update}
                  />
                </SearchPopoverContent>
              </SearchPopover>
              <Button
                size="medium"
                variant="brandWeak"
                data-gtm={GtmVariableName.GnbDownload}
                onClick={clickDownloadButton}
              >
                {t("GlobalNavigationBar.app_download_button_label")}
              </Button>
            </Flex>
            <div className={clsx(css.topRightActionButton, css.mobileOnly)}>
              <Flex
                gap={{
                  base: 2,
                  small: 3,
                }}
                alignItems="center"
                className={css.relatedHamburgerTransition({
                  transitionState: state,
                })}
              >
                <motion.div
                  initial={false}
                  animate={{
                    x: isDetailPage || showSearchButton ? 0 : 44,
                  }}
                  transition={{
                    duration: 0.4,
                    ease: "easeInOut",
                  }}
                >
                  <LocationSelectModal
                    trigger={
                      <SelectRegionButton
                        size="small"
                        currentRegionName={rootData.region.name}
                      />
                    }
                    onSaveRegionToStorage={regionBrowserStorage.update}
                  />
                </motion.div>
                <SearchPopover>
                  <SearchPopoverTrigger asChild>
                    <SearchButton
                      visible={isDetailPage || showSearchButton}
                      label="Search"
                      className={css.searchPopoverTriggerSelector}
                    />
                  </SearchPopoverTrigger>
                  <SearchPopoverAnchor className={css.searchPopoverAnchor} />
                  <SearchPopoverContent
                    className={css.searchPopoverContent}
                    hideWhenDetached
                  >
                    <SearchBar
                      region={rootData.region}
                      keywordType={props.data.keywords.type}
                      keywords={keywordLinks}
                      searchForm={
                        <SearchForm
                          karrotLocalCountryCode={
                            rootData.karrotLocalCountryCode
                          }
                          region={rootData.region}
                          query={props.query}
                          searchAutoFocus={true}
                        />
                      }
                      onSaveRegionToStorage={regionBrowserStorage.update}
                    />
                  </SearchPopoverContent>
                </SearchPopover>
              </Flex>
              <div className={css.topRightHamburgerContainer}>
                <button className={css.hamburgerButton} onClick={toggle}>
                  <div
                    data-gtm={GtmVariableName.GnbHamburger}
                    className={css.hamburgerButtonIcon}
                  >
                    {isEntered ? (
                      <IconXmarkLine size={24} />
                    ) : (
                      <IconHorizline3VerticalLine size={24} />
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <HamburgerMenu
            navLinks={parsedNavLinkWithSubLinksList}
            appDownloadButtonLabel={t(
              "GlobalNavigationBar.app_download_button_label",
            )}
            appDownloadCaptionLabel={t(
              "GlobalNavigationBar.app_download_caption_label",
            )}
            moreSubLinkButtonLabel={t(
              "GlobalNavigationBar.see_more_button_label",
            )}
            onClickAppDownloadButton={clickDownloadButton}
            state={state}
            onDimClick={toggle}
          />
        </PageMaxWidthWrapper>
      </div>
      {props.showFixedTopSearchBar && (
        <FixedTopSearchBar
          region={rootData.region}
          keywordType={props.data.keywords.type}
          keywords={keywordLinks}
          searchForm={
            <SearchForm
              karrotLocalCountryCode={rootData.karrotLocalCountryCode}
              region={rootData.region}
              query={props.query}
            />
          }
          onSaveRegionToStorage={regionBrowserStorage.update}
        />
      )}
      <GlobalQrModal
        open={isQrModalOpen}
        onOpenChange={setQrModalStatus}
        qrLink={GL_APPSFLYER_LINK.Web.Feed}
      />
    </>
  );
};

export default GlobalNavigationBar;

type SearchFormProps = {
  karrotLocalCountryCode: KarrotLocalCountryCode;
  region: { id: string; name: string };
  query: string | null;
  searchAutoFocus?: boolean;
};

function SearchForm(props: SearchFormProps) {
  const { t } = useLocale();
  const onSubmit = useSubmit();

  return (
    <form
      onSubmit={(event) => {
        onSubmit(event.currentTarget);
        const keyword = new FormData(event.currentTarget).get("search");

        if (typeof keyword === "string") {
          trackSubmitSearchKeyword({
            keyword,
            serviceName: Services.BUY_SELL,
            countryCode: props.karrotLocalCountryCode,
          });

          const searchParams = BuySellSearchParams.stringify(
            {
              regionId: props.region.id,
              search: keyword,
            },
            props.region,
          );

          window.location.href = `/${props.karrotLocalCountryCode}/buy-sell/all/${searchParams}`;
          event.preventDefault();
        }
      }}
    >
      <SearchInput
        data-gtm={GtmVariableName.GnbSearch}
        placeholder={t("GlobalNavigationBar.search_input_placeholder")}
        initialValue={props.query}
        name={AllPageSearchParamKey.Search}
        autoFocus={props.searchAutoFocus}
      />
    </form>
  );
}
