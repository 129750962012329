import PageMaxWidthWrapper from "../../PageMaxWidthWrapper";
import type { SearchBarProps } from "./SearchBar";
import { SearchBar } from "./SearchBar";

export function FixedTopSearchBar(props: SearchBarProps) {
  return (
    <PageMaxWidthWrapper>
      <SearchBar {...props} />
    </PageMaxWidthWrapper>
  );
}
